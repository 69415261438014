import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "universes"

class Univers{
    constructor(id, name, session){
        this.id = id
        this.name = name
        this.session = session
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let universes = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_univers = new Univers(doument.id, data.name, data.session)
            universes.push(tmp_univers)
        })

        return universes
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let univers = new Univers(response.id, data.name, data.session)

        return univers
    }

    static async getBySession(sessionId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("session", "==", sessionId))
        let response = await getDocs(documentQuery)

        let universes = []

        response.forEach(document => {
            let data = document.data()
            let tmp_univers = new Univers(document.id, data.name, data.session)
            universes.push(tmp_univers)
        })

        return universes
    } 
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let universes = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_univers = new Univers(document.id, data.name, data.session)
                universes.push(tmp_univers)
            })
            
            if(callback != null){
                callback(universes)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let univers = new Univers(snapshot.id, data.name, data.session)
            
            if(callback != null){
                callback(univers)
            }
        })
        return unsub
    }

    static async listenBySession(sessionId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("session", "==", sessionId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let universes = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_univers =  new Univers(document.id, data.name, data.session)
                universes.push(tmp_univers)
            })
            
            if(callback != null){
                callback(universes)
            }
        })
        return unsub
    } 

    async rename(newName){
        this.name = newName
        await updateDoc(doc(db, collectionName, this.id), {
            name : newName,
        })
    }

    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                name : this.name,
                session : this.session,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                name : this.name,
                session : this.session,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Univers