import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"
import { v4 as uuidv4 } from 'uuid'

let db = getFirestore()
let collectionName = "teams"

class Team{
    constructor(id, name, code, publicName, strategy, slogan, univers, logo, members){
        this.id = id
        this.name = name
        this.code = code
        this.publicName = publicName
        this.strategy = strategy
        this.slogan = slogan
        this.univers = univers
        this.logo = logo
        this.members = members
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let teams = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_team = new Team(doument.id, data.name, data.code, data.publicName, data.strategy, data.slogan, data.univers, data.logo, data.members)
            teams.push(tmp_team)
        })

        return teams
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let team = new Team(response.id, data.name, data.code, data.publicName, data.strategy, data.slogan, data.univers, data.logo, data.members)

        return team
    }

    static async getByCode(code){
        let collectionRef = collection(db, collectionName)
		    let documentQuery = query(collectionRef, where("code", "==", code))
        let response = await getDocs(documentQuery)
        
        let first = true
        let tmp_team = null

        response.forEach(document => {
            if(first){
                let data = document.data()
                tmp_team = new Team(document.id, data.name, data.code, data.publicName, data.strategy, data.slogan, data.univers, data.logo, data.members)
                first = false
            }
        })
        return tmp_team
    }

    static async getByUid(uid){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("uid", "==", uid))
        let response = await getDocs(documentQuery)

        let profile = null
        let first = true

        response.forEach(document => {
            if(first){
                let data = document.data()
                profile = profileFromData(document.id, data)
                first = false
            }else{
                let documentRef = doc(db, collectionName, document.id)
                deleteDoc(documentRef)
            }
        })

        return profile
    } 

    static async getByUnivers(universId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("univers", "==", universId))
        let response = await getDocs(documentQuery)

        let teams = []

        response.forEach(document => {
            let data = document.data()
            let tmp_team = new Team(document.id, data.name, data.code, data.publicName, data.strategy, data.slogan, data.univers, data.logo, data.members)
            teams.push(tmp_team)
        })

        return teams
    } 
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let teams = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_team = new Team(document.id, data.name, data.code, data.publicName, data.strategy, data.slogan, data.univers, data.logo, data.members)
                teams.push(tmp_team)
            })
            
            if(callback != null){
                callback(teams)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let team = new Team(snapshot.id, data.name, data.code, data.publicName, data.strategy, data.slogan, data.univers, data.logo, data.members)
            
            if(callback != null){
                callback(team)
            }
        })
        return unsub
    }

    static async listenByUnivers(universId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("univers", "==", universId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let teams = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_univers =  new Team(document.id, data.name, data.code, data.publicName, data.strategy, data.slogan, data.univers, data.logo, data.members)
                teams.push(tmp_univers)
            })
            
            if(callback != null){
                callback(teams)
            }
        })
        return unsub
    } 

    async rename(newName){
        this.name = newName
        await updateDoc(doc(db, collectionName, this.id), {
            name : newName,
        })
    }

    async resetCode(){
        let newCode = uuidv4().replaceAll("-", "").substring(0, 10)
        this.code = newCode
        await updateDoc(doc(db, collectionName, this.id), {
            code : newCode,
        })
    }

    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                name : this.name,
                code : this.code,
                publicName : this.publicName,
                strategy : this.strategy,
                slogan : this.slogan,
                univers : this.univers,
                logo : this.logo,
                members : this.members,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                name : this.name,
                code : this.code,
                publicName : this.publicName,
                strategy : this.strategy,
                slogan : this.slogan,
                univers : this.univers,
                logo : this.logo,
                members : this.members,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Team