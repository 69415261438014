import {
  getFirestore,
  doc,
  collection,
  query,
  where,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";

let db = getFirestore();
let collectionName = "sessions";

class Session {
  constructor(
    id,
    name,
    isBase,
    machinesConfig,
    productsConfig,
    servicesConfig,
    zonesConfig,
    cvConfig,
    formationsConfig,
    mainParameters,
    hrParameters,
    decisionParameters,
    displayParameters,
    universesParameters,
    productionParameters,
    date
  ) {
    this.id = id;
    this.name = name;
    this.isBase = isBase;
    this.machinesConfig = machinesConfig;
    this.productsConfig = productsConfig;
    this.servicesConfig = servicesConfig;
    this.zonesConfig = zonesConfig;
    this.cvConfig = cvConfig;
    this.formationsConfig = formationsConfig;
    this.mainParameters = mainParameters;
    this.hrParameters = hrParameters;
    this.decisionParameters = decisionParameters;
    this.displayParameters = displayParameters;
    this.universesParameters = universesParameters;
    this.productionParameters = productionParameters;
    this.date = date;
  }

  static async initializeOne(name) {
    let tmp_mainParameters = {
      common: {
        periodDuration: 90,
        conjuncturalIndex: 100,
      },
      financial: {
        initialSocialCaptial: 500000,
        initialTreasury: 500000,
        generalStructuralCost: 30000,
        loanRate: 1,
        loanPenalityRate: 2,
        maxLoanDuration: 20,
        overdraftRate: 5,
        investmentRate: 0,
        discountRate: 3,
        maxDiscount: 50,
        taxRate: 0,
        maximumAmountPeriodsTaxCredit: 0,
      },
      studies: {
        marketStudyPrice: 15000,
        hrStudyPrice: 10000,
        costStudyPrice: 20000,
        attractivityStudyPrice: 15000,
        rseStudyPrice: 10000,
      },
      production: {
        min: 0,
        max: 100000,
        delta: 0.3,
        minRseQualityRate: 0,
        maxRseQualityRate: 30,
      },
      maintenance: {
        isLog: false,
        maximalDecreaseRate: 50,
      },
      deadline: {
        customerDeadline: 30,
        supplierDeadline: 45,
      },
      hrCoeff: {
        socialBudget: 1,
        socialIndex: 1,
        commission: 3,
        efficiency: 4,
      },
      scoreCoeff: {
        price: 5,
        quality: 4,
        communication: 2,
        marketing: 2,
        sellingPower: 3,
      },
      inertialRate: {
        price: 30,
        quality: 30,
        communication: 30,
        marketing: 30,
        sellingPower: 30,
      },
      marketplace: {
        posting: true,
        showInsurance: true,
        box: {
          amount: 10,
          price: 1,
          distribution: 2,
          enabled: true,
        },
        pallet: {
          amount: 100,
          price: 5,
          distribution: 2,
          enabled: true,
        },
        container: {
          amount: 500,
          price: 20,
          distribution: 32,
          enabled: true,
        },
      },
    };
    let tmp_hrParameters = {
      common: {
        minimalSocialBudget: 100,
        maximalSocialBudget: 1000,
        minimalSocialIndex: 100,
        maximalSocialIndex: 150,
        hiringCharges: 20,
        firingCharges: 150,
      },
      worker: {
        baseSalary: 6000,
        productionTime: 400,
      },
      employee: {
        baseSalary: 6000,
        productionTime: 400,
      },
    };
    let tmp_decisionParameters = {
      common: {
        capitalIncreasement: false,
        loan: true,
        discount: false,
        dividend: false,
      },
      studies: {
        purchaseMarketStudy: true,
        purchaseHrStudy: true,
        purchaseCostStudy: true,
        purchaseAttractivityStudy: true,
        purchaseRseStudy: true,
      },
      budget: {
        social: false,
        communication: false,
        marketing: false,
        maintenance: true,
        quality: true,
      },
      forecast: {
        turnover: false,
        result: false,
        treasury: false,
      },
      machine: {
        purchase: true,
        sell: true,
      },
      production: {
        productPrice: true,
        servicePrice: true,
      },
      hr: {
        salary: true,
        training: true,
        salesman: {
          hiring: true,
          firing: true,
          commission: true,
          deployment: true,
        },
        worker: {
          hiring: true,
          firing: true,
        },
        employee: {
          hiring: true,
          firing: true,
        },
        serviceManager: {
          hiring: true,
          firing: true,
        },
        productManager: {
          hiring: true,
          firing: true,
        },
      },
    };
    let tmp_displayParameters = {
      dashboard: true,
      decision: true,
      results: true,
      studies: true,
      informations: true,
      news: true,
      documents: true,
      challenges: true,
      marketplace: true,
      graphs: true,
      graphFinancial: true,
      graphRentability: true,
      graphNetSituation: true,
      graphDebt: true,
      bilan: true,
      resultAccount: true,
      treasuryAccount: true,
      salesTable: true,
      productionTable: true,
      otherDetails: true,
    };

    let tmp_universesParameters = {
      universPrefix: "Univers",
      teamPrefix: "Team",
    };

    let tmp_productionParameters = {};

    let tmp_date = new Date().getTime();

    let tmp_session = new Session(
      null,
      name,
      false,
      {},
      {},
      {},
      {},
      {},
      {},
      tmp_mainParameters,
      tmp_hrParameters,
      tmp_decisionParameters,
      tmp_displayParameters,
      tmp_universesParameters,
      tmp_productionParameters,
      tmp_date
    );
    return tmp_session;
  }

  static async getAll() {
    let collectionRef = collection(db, collectionName);
    let response = await getDocs(collectionRef);

    let sessions = [];

    response.forEach((doument) => {
      let data = doument.data();
      let tmp_session = new Session(
        doument.id,
        data.name,
        data.isBase,
        data.machinesConfig,
        data.productsConfig,
        data.servicesConfig,
        data.zonesConfig,
        data.cvConfig,
        data.formationsConfig,
        data.mainParameters,
        data.hrParameters,
        data.decisionParameters,
        data.displayParameters,
        data.universesParameters,
        data.productionParameters,
        data.date
      );
      sessions.push(tmp_session);
    });

    return sessions;
  }

  static async getOnlySession() {
    let collectionRef = collection(db, collectionName);
    let documentQuery = query(collectionRef, where("isBase", "==", false));
    let response = await getDocs(documentQuery);

    let sessions = [];

    response.forEach((doument) => {
      let data = doument.data();
      let tmp_session = new Session(
        doument.id,
        data.name,
        data.isBase,
        data.machinesConfig,
        data.productsConfig,
        data.servicesConfig,
        data.zonesConfig,
        data.cvConfig,
        data.formationsConfig,
        data.mainParameters,
        data.hrParameters,
        data.decisionParameters,
        data.displayParameters,
        data.universesParameters,
        data.productionParameters,
        data.date
      );
      sessions.push(tmp_session);
    });

    return sessions;
  }

  static async getById(id) {
    let documentRef = doc(db, collectionName, id);
    let response = await getDoc(documentRef);

    let data = response.data();
    let session = new Session(
      response.id,
      data.name,
      data.isBase,
      data.machinesConfig,
      data.productsConfig,
      data.servicesConfig,
      data.zonesConfig,
      data.cvConfig,
      data.formationsConfig,
      data.mainParameters,
      data.hrParameters,
      data.decisionParameters,
      data.displayParameters,
      data.universesParameters,
      data.productionParameters,
      data.date
    );

    return session;
  }

  static listenAll(callback = null) {
    let collectionRef = collection(db, collectionName);
    let unsub = onSnapshot(collectionRef, (snapshot) => {
      let sessions = [];

      snapshot.forEach((document) => {
        let data = document.data();
        let tmp_session = new Session(
          document.id,
          data.name,
          data.isBase,
          data.machinesConfig,
          data.productsConfig,
          data.servicesConfig,
          data.zonesConfig,
          data.cvConfig,
          data.formationsConfig,
          data.mainParameters,
          data.hrParameters,
          data.decisionParameters,
          data.displayParameters,
          data.universesParameters,
          data.productionParameters,
          data.date
        );
        sessions.push(tmp_session);
      });

      if (callback != null) {
        callback(sessions);
      }
    });
    return unsub;
  }

  static listenOnlySession(callback = null) {
    let collectionRef = collection(db, collectionName);
    let documentQuery = query(collectionRef, where("isBase", "==", false));

    let unsub = onSnapshot(documentQuery, (snapshot) => {
      let sessions = [];

      snapshot.forEach((document) => {
        let data = document.data();
        let tmp_session = new Session(
          document.id,
          data.name,
          data.isBase,
          data.machinesConfig,
          data.productsConfig,
          data.servicesConfig,
          data.zonesConfig,
          data.cvConfig,
          data.formationsConfig,
          data.mainParameters,
          data.hrParameters,
          data.decisionParameters,
          data.displayParameters,
          data.universesParameters,
          data.productionParameters,
          data.date
        );
        sessions.push(tmp_session);
      });

      if (callback != null) {
        callback(sessions);
      }
    });
    return unsub;
  }

  static listenById(id, callback = null) {
    let documentRef = doc(db, collectionName, id);
    let unsub = onSnapshot(documentRef, (snapshot) => {
      let data = snapshot.data();
      let session = new Session(
        snapshot.id,
        data.name,
        data.isBase,
        data.machinesConfig,
        data.productsConfig,
        data.servicesConfig,
        data.zonesConfig,
        data.cvConfig,
        data.formationsConfig,
        data.mainParameters,
        data.hrParameters,
        data.decisionParameters,
        data.displayParameters,
        data.universesParameters,
        data.productionParameters,
        data.date
      );

      if (callback != null) {
        callback(session);
      }
    });
    return unsub;
  }

  async changeBaseState(isBase) {
    this.isBase = isBase;
    await updateDoc(doc(db, collectionName, this.id), {
      isBase: this.isBase,
    });
  }

  async rename(newName) {
    this.name = newName;
    await updateDoc(doc(db, collectionName, this.id), {
      name: newName,
    });
  }

  async duplicate(newName) {
    let collectionRef = collection(db, collectionName);
    await addDoc(collectionRef, {
      name: newName,
      isBase: false,
      machinesConfig: this.machinesConfig,
      productsConfig: this.productsConfig,
      servicesConfig: this.servicesConfig,
      zonesConfig: this.zonesConfig,
      cvConfig: this.cvConfig,
      formationsConfig: this.formationsConfig,
      mainParameters: this.mainParameters,
      hrParameters: this.hrParameters,
      decisionParameters: this.decisionParameters,
      displayParameters: this.displayParameters,
      universesParameters: this.universesParameters,
      productionParameters: this.productionParameters,
      date: new Date().getTime(),
    });
    return;
  }

  async save() {
    let collectionRef = collection(db, collectionName);
    if (this.id == null) {
      let response = await addDoc(collectionRef, {
        name: this.name,
        isBase: this.isBase,
        machinesConfig: this.machinesConfig,
        productsConfig: this.productsConfig,
        servicesConfig: this.servicesConfig,
        zonesConfig: this.zonesConfig,
        cvConfig: this.cvConfig,
        formationsConfig: this.formationsConfig,
        mainParameters: this.mainParameters,
        hrParameters: this.hrParameters,
        decisionParameters: this.decisionParameters,
        displayParameters: this.displayParameters,
        universesParameters: this.universesParameters,
        productionParameters: this.productionParameters,
        date: this.date,
      });
      this.id = response.id;
    } else {
      await updateDoc(doc(db, collectionName, this.id), {
        name: this.name,
        isBase: this.isBase,
        machinesConfig: this.machinesConfig,
        productsConfig: this.productsConfig,
        servicesConfig: this.servicesConfig,
        zonesConfig: this.zonesConfig,
        cvConfig: this.cvConfig,
        formationsConfig: this.formationsConfig,
        mainParameters: this.mainParameters,
        hrParameters: this.hrParameters,
        decisionParameters: this.decisionParameters,
        displayParameters: this.displayParameters,
        universesParameters: this.universesParameters,
        productionParameters: this.productionParameters,
        date: this.date,
      });
    }
    return this;
  }

  delete() {
    let documentRef = doc(db, collectionName, this.id);
    deleteDoc(documentRef);
  }
}

export default Session;
