<template>
  <v-sheet class="mb-3" elevation="6">
    <v-app-bar class="white" app flat>
      <v-app-bar-nav-icon @click="isOpen = true"></v-app-bar-nav-icon>
      <v-badge color="accent" left v-if="getTotalNotifCount() > 0">
        <span slot="badge" v-text="getTotalNotifCount()"></span>
      </v-badge>
      <v-toolbar-title
        class="pl-5 d-none d-md-block pointer"
        @click="isOpen = true"
        style="width: 100%"
      >
        <span class="d-flex flex-row justify-start align-centrer pa-3 pl-0">
          <img
            style="max-width: 250px; max-height: 50px; object-fit: contain"
            src="@/assets/images/logo.png"
          />
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-alert
        class="ma-1"
        :type="periodAlertColor"
        outlined
        :icon="false"
        v-if="showPeriodAlert"
        width="100%"
      >
        <span class="d-flex flex-row align-center justify-center">
          <v-icon :color="periodAlertColor" class="px-3"
            >mdi-alert-outline</v-icon
          >
          <span v-text="periodDeltaString"></span>
          <v-icon :color="periodAlertColor" class="px-3"
            >mdi-alert-outline</v-icon
          >
        </span>
      </v-alert>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="pa-3"
            color="white"
            x-large
            v-bind="attrs"
            v-on="on"
            elevation="0"
          >
            <v-avatar
              size="40"
              color="primary"
              class="white--text"
              v-text="profile.lastname[0] + profile.firstname[0]"
            ></v-avatar>
            <span class="d-flex flex-column align-start justify-center px-3">
              <span class="text-subtitle-1"
                ><b v-text="profile.lastname"></b
              ></span>
              <span class="text-subtitle-2" v-text="profile.firstname"></span>
            </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link @click="goToLink('/profile')">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profil</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon color="error">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="error--text"
              >Déconnexion</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer app temporary v-model="isOpen">
      <span class="d-flex justify-start align-centrer pa-3 pb-5">
        <img
          style="max-width: 150px; max-height: 150px; object-fit: contain"
          src="@/assets/images/logo.png"
        />
      </span>
      <v-list dense>
        <span v-for="cat in navigationItems">
          <a
            v-for="item in cat"
            :href="item.to"
            style="text-decoration: none"
            v-if="checkIfVisible(item)"
          >
            <v-list-item link>
              <v-list-item-icon>
                <v-icon
                  :color="item.to == currentRoutePath ? 'primary' : null"
                  v-text="item.icon"
                ></v-icon>
                <v-badge color="accent" overlap v-if="hasNotif(item)">
                  <span slot="badge" v-text="getNotifCount(item)"></span>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </a>
          <v-divider class="my-3" v-if="checkIfFull(cat)"></v-divider>
        </span>
      </v-list>
    </v-navigation-drawer>
  </v-sheet>
</template>

<script>
import { getAuth } from "firebase/auth";

import navigationItems from "@/config/navigationItems";

import Team from "@/classes/Team";
import Univers from "@/classes/Univers";
import Session from "@/classes/Session";
import Period from "@/classes/Period";
import Notif from "@/classes/Notif";

export default {
  name: "Navigation",
  props: ["user", "profile", "userSession"],
  setup() {
    return {
      navigationItems,
      auth: getAuth(),
    };
  },
  data() {
    return {
      unsub: [],
      isOpen: false,
      showPeriodAlert: false,
      periodAlertColor: "success",
      periodDeltaString: "",
      periods: [],
      notifs: {},
    };
  },
  async created() {
    if (
      this.profile &&
      this.profile.role == "User" &&
      this.profile.team != null
    ) {
      let currentTeam = await Team.getById(this.profile.team);
      let currentUnivers = await Univers.getById(currentTeam.univers);
      let currentSession = await Session.getById(currentUnivers.session);

      this.unsub.push(
        Period.listenBySession(currentSession.id, (periods) => {
          this.periods = periods;

          this.updatePeriodDisplayInfo();
          setInterval(this.updatePeriodDisplayInfo, 30000);
        })
      );

      this.unsub.push(
        Notif.listenByProfile(this.profile.id, (notifs) => {
          let tmp_notifs = {};

          for (let notif of notifs) {
            if (!tmp_notifs[notif.type]) {
              tmp_notifs[notif.type] = [];
            }
            tmp_notifs[notif.type].push(notif);
          }

          this.notifs = tmp_notifs;
        })
      );
    }
  },
  computed: {
    currentRoutePath() {
      return this.$route.path;
    },
    currentNotifs() {
      let notifs = [];

      for (let type in this.notifs) {
        if (notifs[type] == undefined) {
          notifs[type] = [];
        }
        for (let notif of this.notifs[type]) {
          if (notif.data.date != undefined) {
            if (notif.data.date <= new Date().getTime()) {
              notifs[type].push(notif);
            }
          } else {
            notifs[type].push(notif);
          }
        }
      }

      return notifs;
    },
    notifCount() {
      let count = {};

      for (let type in this.currentNotifs) {
        count[type] = this.currentNotifs[type].length;
      }

      return count;
    },
  },
  methods: {
    updatePeriodDisplayInfo() {
      let currentTime = new Date().getTime();
      let currentPeriod = null;

      this.periods.forEach((period) => {
        if (period.end > currentTime) {
          if (currentPeriod == null || period.end < currentPeriod.end) {
            currentPeriod = period;
          }
        }
      });

      let prefix = "";
      let deltaTime = 0;
      let deltaString = "";

      if (currentPeriod != null) {
        this.showPeriodAlert = true;

        if (currentPeriod.start > currentTime) {
          prefix = "Début de " + currentPeriod.name + " dans ";
          this.periodAlertColor = "success";
          deltaTime = currentPeriod.start - currentTime;
        } else {
          prefix = "Fin de " + currentPeriod.name + " dans ";
          this.periodAlertColor = "error";
          deltaTime = currentPeriod.end - currentTime;
        }

        let deltaDays = Math.floor(deltaTime / (1000 * 60 * 60 * 24));
        let deltaHours = Math.floor(
          (deltaTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let deltaMinutes = Math.floor(
          (deltaTime % (1000 * 60 * 60)) / (1000 * 60)
        );

        if (deltaDays > 0) {
          deltaString += deltaDays + " jours";
        } else {
          if (deltaHours > 0) {
            deltaString += deltaHours + " h ";
          }
          if (deltaMinutes > 0) {
            deltaString += deltaMinutes + " min";
          }
        }

        this.periodDeltaString = prefix + deltaString;
      } else {
        this.showPeriodAlert = false;
        this.periodDeltaString = "";
      }
    },
    checkIfVisible(item) {
      let isVisible = false;

      let isParamActivated =
        item.displayParameter && this.userSession
          ? this.userSession.displayParameters[item.displayParameter]
          : true;

      if (this.profile.role == "User") {
        isVisible =
          isParamActivated &&
          item.visibility.includes(this.profile.role) &&
          (!item.needTeam || this.profile.team != null);
      } else {
        isVisible = item.visibility.includes(this.profile.role);
      }

      if (item.onlyForDev) {
        if (this.profile.dev) {
          isVisible = true;
        } else {
          isVisible = false;
        }
      }
      return isVisible;
    },
    hasNotif(item) {
      for (let type of item.notifs) {
        if (this.notifCount[type] > 0) {
          return true;
        }
      }
    },
    getNotifCount(item) {
      let count = 0;
      for (let type of item.notifs) {
        if (this.notifCount[type] > 0) {
          count += this.notifCount[type];
        }
      }
      return count;
    },
    getTotalNotifCount() {
      let count = 0;
      for (let type in this.notifCount) {
        count += this.notifCount[type];
      }
      return count;
    },
    checkIfFull(cat) {
      let count = 0;
      cat.forEach((item) => {
        if (this.checkIfVisible(item)) {
          count++;
        }
      });
      return count > 0;
    },
    goToLink(to) {
      if (this.$route.path != to) {
        this.$router.push(to);
      }
    },
    logout() {
      this.auth.signOut();
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
