import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "periods"

class Periods{
    constructor(id, name, session, start, end){
        this.id = id
        this.name = name
        this.session = session
        this.start = start
        this.end = end
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let periods = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_period = new Periods(doument.id, data.name, data.session, data.start, data.end)
            periods.push(tmp_period)
        })

        return periods
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let period = new Periods(response.id, data.name, data.session, data.start, data.end)

        return period
    }

    static async getBySession(sessionId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("session", "==", sessionId))
        let response = await getDocs(documentQuery)

        let periods = []

        response.forEach(document => {
            let data = document.data()
            let tmp_period = new Periods(document.id, data.name, data.session, data.start, data.end)
            periods.push(tmp_period)
        })

        return periods
    } 
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let periods = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_period = new Periods(document.id, data.name, data.session, data.start, data.end)
                periods.push(tmp_period)
            })
            
            if(callback != null){
                callback(periods)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let period = new Periods(snapshot.id, data.name, data.session, data.start, data.end)
            
            if(callback != null){
                callback(period)
            }
        })
        return unsub
    }

    static async listenBySession(sessionId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("session", "==", sessionId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let periods = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_period =  new Periods(document.id, data.name, data.session, data.start, data.end)
                periods.push(tmp_period)
            })
            
            if(callback != null){
                callback(periods)
            }
        })
        return unsub
    } 

    async rename(newName){
        this.name = newName
        await updateDoc(doc(db, collectionName, this.id), {
            name : newName,
        })
    }

    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                name : this.name,
                session : this.session,
                start : this.start,
                end : this.end
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                name : this.name,
                session : this.session,
                start : this.start,
                end : this.end
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Periods