<template>
  <v-footer app class="d-flex justify-space-around align-center white">
    <i
      class="text-center"
      v-text="'Copyright ©' + new Date().getFullYear() + ' Ludimation'"
    ></i>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
