import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "notifs"

class Notif{
    constructor(id, profile, type, data){
        this.id = id
        this.profile = profile
        this.type = type
        this.data = data
    }

    static async initializeOne(profileId, type){
        let tmp_notif = new Notif(null, profileId, type, {})
        return tmp_notif
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let notifs = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_notif = new Notif(doument.id, data.profile, data.type, data.data)
            notifs.push(tmp_notif)
        })

        return notifs
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let notif = new Notif(response.id, data.profile, data.type, data.data)

        return notif
    }

    static async getByProfile(profileId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("profile", "==", profileId))
        let response = await getDocs(documentQuery)

        let notifs = []

        response.forEach(document => {
            let data = document.data()
            let tmp_notif = new Notif(document.id, data.profile, data.type, data.data)
            notifs.push(tmp_notif)
        })

        return notifs
    }
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let notifs = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_notif = new Notif(document.id, data.profile, data.type, data.data)
                notifs.push(tmp_notif)
            })
            
            if(callback != null){
                callback(notifs)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let notif = new Notif(snapshot.id, data.profile, data.type, data.data)
            
            if(callback != null){
                callback(notif)
            }
        })
        return unsub
    }

    static async listenByProfile(profileId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("profile", "==", profileId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let notifs = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_notif =  new Notif(document.id, data.profile, data.type, data.data)
                notifs.push(tmp_notif)
            })
            
            if(callback != null){
                callback(notifs)
            }
        })
        return unsub
    } 

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                profile : this.profile,
                type : this.type,
                data : this.data,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                profile : this.profile,
                type : this.type,
                data : this.data,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Notif