let navigationItems = [
  [
    {
      name: "Mon entreprise",
      to: "/",
      icon: "mdi-factory",
      visibility: ["User"],
      notifs: [],
      needTeam: false,
      displayParameter: "dashboard",
    },
    {
      name: "Entreprises",
      to: "/",
      icon: "mdi-factory",
      visibility: ["Animator", "Admin", "SuperAdmin"],
      notifs: [],
      needTeam: false,
      displayParameter: "dashboard",
    },
  ],
  [
    {
      name: "Décisions",
      to: "/decisions",
      icon: "mdi-gavel",
      visibility: ["User", "Animator", "Admin", "SuperAdmin"],
      notifs: [],
      needTeam: true,
      displayParameter: "decision",
    },
    {
      name: "Bonus/Malus",
      to: "/bonus",
      icon: "mdi-plus-circle-multiple-outline",
      visibility: ["Admin", "SuperAdmin"],
      notifs: [],
      needTeam: true,
      displayParameter: false,
    },
    {
      name: "Marketplace",
      to: "/marketplace",
      icon: "mdi-cart-outline",
      visibility: ["User", "Animator", "Admin", "SuperAdmin"],
      notifs: [],
      needTeam: true,
      displayParameter: "marketplace",
    },
  ],
  [
    {
      name: "Résultats",
      to: "/results",
      icon: "mdi-cash",
      visibility: ["User", "Animator", "Admin", "SuperAdmin"],
      notifs: [],
      needTeam: true,
      displayParameter: "results",
    },
    {
      name: "Graphiques",
      to: "/graphs",
      icon: "mdi-chart-line",
      visibility: ["User", "Animator", "Admin", "SuperAdmin"],
      notifs: [],
      needTeam: true,
      displayParameter: "graphs",
    },
    {
      name: "Études",
      to: "/researches",
      icon: "mdi-magnify",
      visibility: ["User", "Animator", "Admin", "SuperAdmin"],
      notifs: [],
      needTeam: true,
      displayParameter: "studies",
    },
    {
      name: "Études Admin",
      to: "/adminResearches",
      icon: "mdi-account-search-outline",
      visibility: ["Animator", "Admin", "SuperAdmin"],
      notifs: [],
      needTeam: true,
      displayParameter: false,
    },
  ],
  [
    {
      name: "Challenges",
      to: "/challenges",
      icon: "mdi-trophy",
      visibility: ["User", "Animator", "Admin", "SuperAdmin"],
      notifs: ["challenge"],
      needTeam: true,
      displayParameter: "challenges",
    },
  ],
  [
    {
      name: "Informations",
      to: "/info",
      icon: "mdi-file-document-edit-outline",
      visibility: ["User", "Animator", "Admin", "SuperAdmin"],
      notifs: ["infos"],
      needTeam: true,
      displayParameter: "informations",
    },
    {
      name: "News",
      to: "/news",
      icon: "mdi-newspaper",
      visibility: ["User", "Animator", "Admin", "SuperAdmin"],
      notifs: ["news"],
      needTeam: true,
      displayParameter: "news",
    },
    {
      name: "Autres documents",
      to: "/documents",
      icon: "mdi-file-document-outline",
      visibility: ["User", "Animator", "Admin", "SuperAdmin"],
      notifs: ["documents"],
      needTeam: true,
      displayParameter: "documents",
    },
  ],
  [
    {
      name: "Sessions",
      to: "/sessions",
      icon: "mdi-folder-account-outline",
      visibility: ["Admin", "SuperAdmin"],
      notifs: [],
      needTeam: true,
      displayParameter: false,
    },
    {
      name: "Périodes",
      to: "/periods",
      icon: "mdi-calendar",
      visibility: ["Admin", "SuperAdmin"],
      notifs: [],
      needTeam: true,
      displayParameter: false,
    },
    {
      name: "Teams",
      to: "/teams",
      icon: "mdi-account-group",
      visibility: ["Admin", "SuperAdmin"],
      notifs: [],
      needTeam: true,
      displayParameter: false,
    },
    {
      name: "Users",
      to: "/users",
      icon: "mdi-account",
      visibility: ["Admin", "SuperAdmin"],
      notifs: [],
      needTeam: true,
      displayParameter: false,
    },
    {
      name: "Assets",
      to: "/assets",
      icon: "mdi-package-variant-closed",
      visibility: ["Admin", "SuperAdmin"],
      notifs: [],
      needTeam: true,
      displayParameter: false,
    },
  ],
];

export default navigationItems;
