import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "profiles"

class Profile{
    constructor(id, uid, email, role, firstname, lastname, team, dev){
        this.id = id
        this.uid = uid
        this.email = email
        this.role = role
        this.firstname = firstname
        this.lastname = lastname
        this.team = team
        this.dev = dev
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let profiles = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_profile = new Profile(doument.id, data.uid, data.email, data.role, data.firstname, data.lastname, data.team, data.dev)
            profiles.push(tmp_profile)
        })

        return profiles
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let profile = new Profile(response.id, data.uid, data.email, data.role, data.firstname, data.lastname, data.team, data.dev)

        return profile
    }

    static async getByUid(uid){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("uid", "==", uid))
        let response = await getDocs(documentQuery)

        let profile = null
        let first = true

        response.forEach(document => {
            if(first){
                let data = document.data()
                profile = new Profile(document.id, data.uid, data.email, data.role, data.firstname, data.lastname, data.team, data.dev)
                first = false
            }else{
                let documentRef = doc(db, collectionName, document.id)
                deleteDoc(documentRef)
            }
        })

        return profile
    } 

    static async getByTeam(teamId){
        if(teamId == null){
            return []
        }else{
            let collectionRef = collection(db, collectionName)
            let documentQuery = query(collectionRef, where("team", "==", teamId))
            let response = await getDocs(documentQuery)
    
            let profiles = []
    
            response.forEach(document => {
                let data = document.data()
                let tmp_profile = new Profile(document.id, data.uid, data.email, data.role, data.firstname, data.lastname, data.team, data.dev)
                profiles.push(tmp_profile)
            })
            return profiles
        }
    } 
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let profiles = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_profile = new Profile(document.id, data.uid, data.email, data.role, data.firstname, data.lastname, data.team, data.dev)
                profiles.push(tmp_profile)
            })
            
            if(callback != null){
                callback(profiles)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let profile = new Profile(snapshot.id, data.uid, data.email, data.role, data.firstname, data.lastname, data.team, data.dev)
            
            if(callback != null){
                callback(profile)
            }
        })
        return unsub
    }

    static listenByUid(uid, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("uid", "==", uid))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let profile = null
            let first = true

            snapshot.forEach(document => {
                if(first){
                    let data = document.data()
                    profile = new Profile(document.id, data.uid, data.email, data.role, data.firstname, data.lastname, data.team, data.dev)
                    first = false
                }else{
                    let documentRef = doc(db, collectionName, document.id)
                    deleteDoc(documentRef)
                }
            })
            
            if(callback != null){
                callback(profile)
            }
        })
        return unsub
    }

    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                uid: this.uid,
                email: this.email,
                role: this.role,
                firstname: this.firstname,
                lastname: this.lastname,
                team: this.team,
                dev: this.dev,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                uid: this.uid,
                email: this.email,
                role: this.role,
                firstname: this.firstname,
                lastname: this.lastname,
                team: this.team,
                dev: this.dev,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Profile